import * as React from 'react'
import { styles } from './styles'
import CircularProgress from '@material-ui/core/CircularProgress'
import { COLORS } from '../../constants'

const CHRSpinner = () => {
  const classes = styles()
  return (
    <div className={classes.wrapper}>
      <CircularProgress style={{ color: COLORS.black }} />
    </div>
  )
}

export default CHRSpinner
