import { setItemToLocalStorage } from '../utils/setLocalStorage'
import axios from '../src/axios'
import { ENDPOINTS } from './constants'
const getPrevValueFromStorage = (key: string) => {
  if (typeof window === 'undefined') return null
  let value = window.localStorage.getItem(key)
  if (value === 'null') value = null
  return value
}
const initialState = {
  authAttempted: false,
  userId: getPrevValueFromStorage('user_id'),
  username: getPrevValueFromStorage('username'),
  auth: null,
  userProfile: {},
  settings: {},
  shoppingUserProfile: {},
  instagramInfo: {},
  afterAddShoppingItem: false,
}

const appStateReducer = (state: any, action: any) => {
  switch (action.type) {
    case 'AUTH_CHANGE': {
      setItemToLocalStorage('user_id', action.userId)
      setItemToLocalStorage('username', action.userProfile.userName)

      return {
        ...state,
        auth: action.auth,
        authAttempted: true,
        userId: action.userId,
        username: action.userProfile.userName,
        userProfile: action.userProfile,
      }
    }
    case 'SIGN_IN': {
      const userId = action.auth.attributes['custom:user_id']
      setItemToLocalStorage('user_id', userId)
      setItemToLocalStorage('username', action.userProfile.userName)
      window.localStorage.setItem('loggedInRefresh', Date.now().toString())
      // axios.get(ENDPOINTS.increaseVisitsByOne.replace(':id', userId))
      return {
        ...state,
        auth: action.auth,
        authAttempted: true,
        userId: userId,
        username: action.userProfile.userName,
        userProfile: action.userProfile,
      }
    }
    case 'UPDATE_USER_DATA': {
      return {
        ...state,
        username: action.userProfile.userName,
        userProfile: action.userProfile,
      }
    }
    case 'UPDATE_SHOPPING_USER_DATA': {
      return {
        ...state,
        shoppingUserProfile: action.shoppingUserProfile,
      }
    }
    case 'UPDATE_AFTER_ADDING_TO_SHOPPING_BOARD': {
      return {
        ...state,
        afterAddShoppingItem: action.afterAddShoppingItem,
      }
    }
    case 'UPDATE_INSTAGRAM_INFOMATION': {
      return {
        ...state,
        instagramInfo: action.instagramInfo,
      }
    }
    case 'SIGN_OUT': {
      window.localStorage.removeItem('user_id')
      window.localStorage.removeItem('username')
      window.localStorage.removeItem('profile')
      window.localStorage.removeItem('personalInfoTrigger')
      window.localStorage.setItem('logoutAllTabs', Date.now().toString())
      sessionStorage.removeItem('activeOnSite')
      return {
        ...initialState,
        userId: null,
        username: null,
      }
    }
    case 'UPDATE_SETTINGS': {
      return {
        ...state,
        settings: action.settings,
      }
    }
    default:
      throw new Error('Unknown action type')
  }
}

export { initialState }
export default appStateReducer
