import { TrackingCase } from './trackingCases'
import { tracker } from '../src/systemLogs'

export const setItemToLocalStorage = (key: string, value: string) => {
  try {
    window.localStorage.setItem(key, value)
  } catch (err) {
    tracker.track(
        TrackingCase.UserTracking,
        `Set item to local storage failed`,
        {
          errorMessage: err.message,
          item: value,
        }
      )
  }
}
