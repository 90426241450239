import { createTheme } from '@material-ui/core/styles'
import graphikBlack from '../assets/Fonts/Graphik/Graphik-Bold.otf'
import graphikLight from '../assets/Fonts/Graphik/Graphik-Light.otf'
import graphikRegular from '../assets/Fonts/Graphik/Graphik-Regular.otf'
import graphikMedium from '../assets/Fonts/Graphik/Graphik-Medium.otf'
import schnyderLight from '../assets/Fonts/Schnyder/Schnyder-L-Light.otf'
import schnyderXLLight from '../assets/Fonts/Schnyder/Schnyder-XL-Light.otf'
import { BREAKPOINTS } from '../constants'

// A custom theme for this app
const theme = createTheme({
  props: {
    // Name of the component
    MuiButtonBase: {
      // The properties to apply
      disableRipple: true, // No more ripple, on the whole application!
    },
  },
  palette: {
    primary: {
      main: '#000',
    },
    secondary: {
      main: '#fff',
    },
    error: {
      main: '#FF0000',
    },
    background: {
      default: '#fff',
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  typography: {
    fontSize: 16,
    htmlFontSize: 16,
    h1: {
      fontFamily: 'Schnyder XL Light',
      fontSize: '2.25rem' /*equals 36px*/,
      lineHeight: '40px',
      marginBottom: 35,
      textTransform: 'lowercase',
      [`@media (min-width: ${BREAKPOINTS.md}px)`]: {
        fontSize: '3.75rem' /*equals 60px*/,
        lineHeight: '86px',
      },
    },
    h2: {
      fontFamily: 'Schnyder XL Light',
      fontSize: '1.875rem' /*equals 30px*/,
      marginBottom: 30,
      [`@media (min-width: ${BREAKPOINTS.md}px)`]: {
        fontSize: '2.5rem' /*equals 40px*/,
      },
    },
    h3: {
      fontFamily: 'Schnyder XL Light',
      fontSize: '1.75rem' /*equals 28px*/,
      marginBottom: 15,
      [`@media (min-width: ${BREAKPOINTS.md}px)`]: {
        marginBottom: 30,
        fontSize: '1.875rem' /*equals 30px*/,
      },
    },
    h4: {
      fontFamily: 'Schnyder XL Light',
      fontWeight: 400,
      fontSize: '2.8125rem' /*equals 45px*/,
      marginTop: 0,
      marginBottom: 30,
      [`@media (min-width: ${BREAKPOINTS.md}px)`]: {
        fontSize: '3.75rem' /*equals 60px*/,
      },
    },
    h5: {
      fontFamily: 'Schnyder XL Light',
      fontWeight: 400,
      fontSize: '1.5rem' /*equals 24px*/,
      marginTop: 0,
      marginBottom: 25,
    },
    subtitle1: {
      fontFamily: 'Graphik Light',
      fontSize: '1rem',
      marginBottom: 15,
      textTransform: 'lowercase',
    },
    subtitle2: {
      fontFamily: 'Schnyder XL Light',
      fontSize: '1.875rem' /*equals 30px*/,
    },
    button: {
      fontFamily: 'Graphik Medium',
      fontSize: '0.75rem' /*equals 12px*/,
      letterSpacing: '1.2px',
      textTransform: 'lowercase',
    },
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': [
          {
            fontFamily: 'Schnyder L Light',
            src: `url(${schnyderLight}) format('opentype')`,
            fontStyle: 'light',
            fontDisplay: 'swap',
            fontWeight: 300,
          },
          {
            fontFamily: 'Graphik Black',
            fontStyle: 'normal',
            fontDisplay: 'swap',
            fontWeight: 400,
            src: `url(${graphikBlack}) format('opentype')`,
          },
          {
            fontFamily: 'Graphik Regular',
            fontStyle: 'normal',
            fontDisplay: 'swap',
            fontWeight: 400,
            src: `url(${graphikRegular}) format('opentype')`,
          },
          {
            fontFamily: 'Graphik Light',
            fontStyle: 'normal',
            fontDisplay: 'swap',
            fontWeight: 400,
            src: `url(${graphikLight}) format('opentype')`,
          },
          {
            fontFamily: 'Schnyder XL Light',
            fontStyle: 'normal',
            fontDisplay: 'swap',
            fontWeight: 400,
            src: `url(${schnyderXLLight}) format('opentype')`,
          },
          {
            fontFamily: 'Graphik Medium',
            fontStyle: 'normal',
            fontDisplay: 'swap',
            fontWeight: 400,
            src: `url(${graphikMedium}) format('opentype')`,
          },
        ],
      },
    },
    // this is the right place to override rating styles after the end of MuiCssBaseline
    MuiRating: {
      root: {
        color: 'black',
        '&.Mui-disabled': {
          opacity: 1,
        },
      },
      sizeSmall: {
        fontSize: '0.8rem',
      },
    },
    MuiDialog: {
      paperScrollPaper: {
        maxHeight: 'unset',
      },
    },
    MuiPaper: {
      rounded: {
        borderRadius: 0,
      },
    },
  },
})

export default theme
