import React from 'react'
import { TrackingCase } from '../../../utils/trackingCases'
import { tracker } from '../../systemLogs'
// import { ENDPOINTS } from '../../constants'

class ErrorBoundary extends React.Component {
  props: any
  state: { hasError: boolean }
  constructor(props: any) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error: any) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  componentDidCatch(error: any, errorInfo: any) {
    tracker.track(TrackingCase.UserTracking, `Error Boundary`, {
      errorMessage: error.message,
    })
  }

  render() {
    return this.props.children
  }
}

export default ErrorBoundary
