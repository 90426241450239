import { makeStyles } from '@material-ui/core/styles'

export const styles = makeStyles(theme => ({
  wrapper: {
    width: '100%',
    height: 300,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}))
